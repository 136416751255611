//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/bootstrap";

$theme:#208380;
$progress-bar: #208380;
$white:#ffffff;
$black:#000000;
$darkgray: #363636;
$button: #208380;
$body: #4C4B4B;
$answer-btn-hover: #1C7C79;



.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #208380;
    --bs-btn-border-color: #208380;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1C7C79;
    --bs-btn-hover-border-color: #1C7C79;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #86D0CE;
    --bs-btn-active-border-color: #86D0CE;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #86D0CE;
    --bs-btn-disabled-border-color: #86D0CE;
}